import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Menu,
  message,
  Modal,
  Table,
  Avatar,
  Card,
  Dropdown,
  Row,
  Col,
  Tag,
  Typography,
  Input,
  notification,
  Spin
} from "antd";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
  getAllOrders,
  updateOrder,
  cancelOrder,
  getMerchantByDispatch,
  getOrderedItems,
  getOrder,
  displayOrder,
  payOrder
} from "../../../../redux/actions/orderActions";
import { openOrderScreen as activateKitchenScreen } from "../../../../redux/actions/orderActions";
import api from "../../../../api";
import { getAllProducts } from "../../../../redux/actions/productActions";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import WithApolloClient from "../../../../utils/withApolloClient";
import { DeliveredProcedureOutlined, SearchOutlined } from "@ant-design/icons";
import { Icon } from "@ant-design/compatible";
import { connect, } from "react-redux";
import "./index.scss";
import RequestDeliveryModal from "../../../Sales/_partials/RequestDeliveryModal";
import strings from "../../../../strings";
import { useQuery, useLazyQuery, useSubscription } from "@apollo/client";
import { GET_ORDERS,
         GET_INSTORE_ORDERS, 
         GET_ONE_ORDERITEM, 
         DELETE_ONE_ORDER, 
         DELETE_ORDERITEMS,
         GET_ONE_ORDER,
         READY_ONE_ORDER,
         PAY_ONE_ORDER, 
         SUBSCRIBE_INSTORE_ORDERS
        } from "../../../../constants";
import addNotification from 'react-push-notification';
import { getAllSales, recordSale } from "../../../../redux/actions/saleActions";
import { useHistory } from "react-router-dom";
import ShortSMSModal from "./modal/sendMessage";
import ComponentToPrint from "./partials/componentToPrint";



const Orders = (props) => {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [productModalVisible, setProductModalVisible] = useState(false);
  const [confirmPaymentVisible, setConfirmPaymentVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [currentOrder, setCurrentOrder] = useState(null);
  // const [ homeBranch, setHomeBranch ] = useState(null);
  const [requestDeliveryModalOpen, setRequestDeliveryModalOpen] =
    useState(null);
  const history = useHistory(); 
  // const saleReciptRef = useRef();
  const homeBranch = localStorage.getItem("homebranch") ? JSON.parse(localStorage.getItem("homebranch")) : {id: null};

  const allCustomers = props.state.customer.allCustomers;
  const allProducts = props.state.product.allProducts;
  const user = props.state.merchant.user;
  const orders = props.state.orderItems.order_items;
  const isItFetched = props.state.orderItems.isItFetched;
  const savedOrders = props.state.orders.orders;
  const isOrderPaid = props.state.allOrders.isOrderPaid;
  const paidID = props.state.allOrders.paidID;
  const componentRef = useRef(); 

  

  const { 
    data: orderData 
  } = useQuery(GET_ORDERS);

  const { 
    data: inStoreData, 
    refetch, 
  } = useQuery(
    GET_INSTORE_ORDERS, 
    {variables: 
      { 
        merchant_id: user?.business_branch?.home === false ? user?.employer?.id  
        || user.id : user?.employer?.id  || user.id,
        branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id, homebranch_id: !user?.business_branch ? homeBranch.id : null 
      }});

 
  const { 
    data: itemData, 
    refetch: refetchItem 
  } = useQuery(
    GET_ONE_ORDERITEM, 
    {variables: 
      { merchant_id: user?.business_branch?.home === false ? user?.employer?.id 
        || user.id : user?.employer?.id || user.id,
         branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id, homebranch_id: !user?.business_branch ? homeBranch.id : null 
      }});

  const [ deleteOneOrder ] = useLazyQuery(DELETE_ONE_ORDER);

  const [ 
    deleteOneOrderItem
  ] = useLazyQuery(DELETE_ORDERITEMS);

  const [ getOneOrder ] = useLazyQuery(GET_ONE_ORDER);

  const [ readyOneOrder ] = useLazyQuery(READY_ONE_ORDER);

  const [ payOneOrder ] = useLazyQuery(PAY_ONE_ORDER);
  const [orderArray, setOrderArray ] = useState([]);
  const [ productToDisplay, setProductToDisplay ] = useState([]);
  const [ customerToDisplay, setCustomerToDisplay ] = useState([]);
  const [ OnPointOrders, setOnPointOrders ] = useState(true);
  const [ itemToDisplay, setItemToDisplay] = useState([])
  const [orderID, setOrderID ] = useState()
  const [ orderState, setOrderState ] = useState([]);
  const [ payModal, setPayModal ] = useState(false);
  const [ payModalVisible, setPayModalVisible ] = useState(false);
  const [customer, setCustomer ] = useState({});
  const [ openMessageModal, setOpenMessageModal] = useState(false);
  const [ smsBalance, setSmsBalance ] = useState(null);
 
//get the cusotmer///////////////////////////////////////////////////
  useEffect(()=>{
    setCustomer(()=>{
      return allCustomers.filter((item)=>{
        return item?.user_id === currentOrder && currentOrder?.customer?.user_id
      })
    })
  }, [currentOrder, allCustomers]);


  useEffect(() => {
    // getOrders();
    props.getAllCustomers();
    props.getAllProducts();
    props.getMerchantByDispatch();
    getMerchantSMSBalance();
    getAllBundleProduct();
  }, []);

  useEffect(()=>{
    isOrderPaid && payOneOrder({variables: {id: paidID}});

    setTimeout(()=>{
      refetch();
    }, 3000);
  }, [isOrderPaid, paidID])

  useEffect(()=>{
    inStoreData && props.getOrder(inStoreData?.getInstoreOrders);
  }, [inStoreData, savedOrders]);
  
 
  /////etting subscription///////////////////////////////////////////
  const { data: inSData} = useSubscription(SUBSCRIBE_INSTORE_ORDERS, {
    onData: (data)=>{
      const newOrdered =  data.data.data.subscribeInstoreOrders;

      const newOrderState = [...savedOrders, data.data.data.subscribeInstoreOrders]
      
      refetch();  
      refetchItem();
      
      itemData && props.getOrderedItems(newOrdered, itemData);

      newOrderState && props.getOrder(newOrderState);
      },
      variables: { merchant_id: user?.employer ? user?.employer?.id : user?.id, branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id}
  });

  // const getOrders = () => {
  //   setLoading(true);
  //   props
  //     .getAllOrders()
  //     .then((res) => {
  //       setLoading(false);
  //       if (res && res.status === 200) {
  //         // setAllOrders(orderData && orderData.getOrders)
  //         setAllOrders(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  const getMerchantSMSBalance = async () => {
    try {
      const res = await api.merchants.getMerchantSMSBalance();

      if (res.status === 200) {
        setSmsBalance(res.data.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Get Product bundle
  const getAllBundleProduct = async () => {
    const response = await api.product.getAllMerchantBundleProducts(user.id);
    if(response.status === 200){
      localStorage.setItem("bundle_product", JSON.stringify(response.data));
    }
  }

  const handleConfirmOrder = () => {
    setConfirmLoading(true);
    props
      .updateOrder(orderId)
      .then((res) => {
        setConfirmLoading(false);
        // if (res.status === 200) {
        message.success(strings.orderConfirmedSuccessfully);
        setConfirmModalVisible(false);
        setProductModalVisible(false);
        // getOrders();
        // }
        // else message.error(res.data.message);
      })
      .catch((err) => {
        setConfirmLoading(false);
        message.error(err.message);
      });
  };
//handlePay orders by graphQl /////////////////////////////////////////////
  const handlePayOrderbyGraph =(orderID)=>{
    setConfirmLoading(true);

    payOneOrder({variables: {id: orderID}})
    setConfirmLoading(false);

    setTimeout(()=>{
      refetch();
      refetchItem();
    }, 3000);
    
    message.success(strings.paymentConfirmSuccessfully);

    setConfirmPaymentVisible(false)
    setProductModalVisible(false);
}

//handleConfirm orders by graphQl //////////////////////////////////////////////
  const handleConfirmOrderbyGraph =(orderID)=>{
    setConfirmLoading(true);
    readyOneOrder({variables: {id: orderID}})
    setConfirmLoading(false);

    setTimeout(()=>{
      refetch();
      refetchItem();
      message.success(strings.orderConfirmedSuccessfully);
    }, 3000);

    setConfirmModalVisible(false);
    setProductModalVisible(false);
  }

  //handleProcess orders by graphQl //////////////////////////////////////////////
  const handleProcessOrderbyGraph = (orderID)=>{
    setConfirmLoading(true);

    const timing = new Date(currentOrder.created_at);
    const username = user && user?.first_name + user?.last_name;
    const checkUser = user && user?.role_id === 1 ? user?.id : user?.username
    
    const reference_code = timing.getTime() + "_" + checkUser;
    
    getOneOrder({variables: {id: orderID, processed_by: user && user?.role_id === 1 ? "Admin" : user?.username, reference_code: reference_code}});

    setConfirmLoading(false);

    setTimeout(()=>{

      refetch();
      refetchItem();
      message.success(strings.orderProcessedSuccessfully);
    
    }, 3000);

    
    setProcessModalVisible(false);
    setProductModalVisible(false);
  }

  //handleCancel orders by graphQl //////////////////////////////////////////////
  const handleCancelOrderbyGraph =(orderID)=>{
    setConfirmLoading(true);
    deleteOneOrder({variables: {id: orderID}})
    deleteOneOrderItem({variables: {order_id: orderID}})
    setConfirmLoading(false);

    setTimeout(()=>{
      refetch();
      refetchItem();
      message.success(strings.orderCancelledSuccessfully);
    }, 2000)

    

    setCancelModalVisible(false);
    setProductModalVisible(false);
  }

  const handleCancelOrder = () => {
    setConfirmLoading(true);
    props
      .cancelOrder(orderId)
      .then((res) => {
        setConfirmLoading(false);
        //if (res.status === 200) {
        message.success(strings.orderCancelledSuccessfully);
        setCancelModalVisible(false);
        setProductModalVisible(false);
        // getOrders();
        //}
        // else message.error(res.data.message);
      })
      .catch((err) => {
        setConfirmLoading(false);
        message.error(err.message);
      });
  };

  const handleConfirmOrderProcess=(type, id)=>{
    switch (type) {
      case "pending":
        setProcessModalVisible(true)
      break;
      case "processing":
        setConfirmModalVisible(true);
      break;
      case "ready":
        setPayModalVisible(true);
      break;
      case "paid":
        return "Order Completed";
      break;
      default:
        return type;
        break;
      }
  }

  //to handle checkout to cart///////////////////////////////////////////
  const handleCheckoutToCart = ()=>{
    const newCurrentOrder = {...currentOrder }
    newCurrentOrder.products = orders;
    const transaction = orders && orders;
    const fromInstoreOrder = true;
    const userId = newCurrentOrder?.customer?.user_id || null;
    const customervalue = newCurrentOrder && newCurrentOrder?.customer?.user_id
    const selectedCustomer = allCustomers.find(
      (data) => Number(data.user_id) === Number(userId)
    );
    const total = newCurrentOrder && newCurrentOrder?.total;
    const referenceDate = new Date(newCurrentOrder?.created_at)
    const payment_reference = "ud_IS " + newCurrentOrder?.order_id +"_" + referenceDate.getTime();
    const reference_code = newCurrentOrder && newCurrentOrder?.reference_code;
    history.push({
      pathname: "/sales",
      state: {
        fromInstoreOrder,
        transaction,
        selectedCustomer: selectedCustomer ? selectedCustomer : "",
        customerValue: customervalue,
        payment_reference,
        reference_code,
        total,
        order_id: newCurrentOrder?.id,
      },
    });
  }

//open product modal ///////////////////////////////////////////////////////
  const openProductModal = (order) => {
    setCurrentOrder(order);
    setOrderID(order.id);
    setItemToDisplay(itemData);

    setProductModalVisible(true);
  };

//open pay modal /////////////////////////////////////////////////////////
const openPayModal = ()=>{
    // setCurrentOrder(order);

    // props.getOrderedItems(order, itemData);
    // setOrderID(order.id);
    setPayModalVisible(false)
    setPayModal(true);
}

const orderType = (type)=>{
  switch (type) {
    case "pending":
      return strings.processOrder;
    break;
    case "processing":
      return strings.confirmOrder;
    break;
    case "ready":
      return "Pay";
    break;
    case "paid":
      return "Order Completed";
    break;
    case "cancelled":
      return "Order"+" "+type;
    break;
    default:
      return type;
      break;
  }
}

  const menu = (record) => {
    if (!record) return;
    else
      return (
        <Menu>
        <Menu.Item key={1}>
          <div>
            <ReactToPrint
              trigger={() => (
                <Button style={{ width: "10.8rem" }}>
                  {strings.printOrder}
                </Button>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
             <ComponentToPrint ref={componentRef} order={record} transaction={orders} user={user}/>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item key={2}>
            <Button
              onClick={(e) => {
                setOrderId(record?.id);
                handleConfirmOrderProcess(record?.status, record?.id);
              }}
              style={{ width: "10.8rem" }}
              disabled={record?.status === "paid" || record?.status === "cancelled"}
            >
              {orderType(record.status)}
            </Button>
          </Menu.Item>
          {/* <Menu.Item>
            <Button
              onClick={(e) => {
                setOrderId(record.id);
                setConfirmPaymentVisible(true);
              }}
              style={{ width: "10.8rem" }}
              disabled={record.status !== "ready"}
            >
              {strings.confirmPayment}
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              onClick={(e) => {
                setOrderId(record.id);
                setConfirmModalVisible(true);
              }}
              style={{ width: "10.8rem" }}
              disabled={record.status !== "processing"}
            >
              {strings.confirmOrder}
            </Button>
          </Menu.Item>*/}
          {/* <Menu.Item>
            <Button
              onClick={(e) => {
                trial();
              }}
              style={{ width: "10.8rem" }}
              // disabled={record.status !== "pending"}
            >
              {strings.processOrder}
            </Button>
          </Menu.Item>  */}
          <Menu.Item key={3}>
            <Button
              onClick={(e) => {
                setOrderId(record.id);
                setCancelModalVisible(true);
              }}
              style={{ width: "10.8rem" }}
              disabled={record.status === "cancelled" || record.status === "paid"}
            >
              {strings.cancelOrder}
            </Button>
          </Menu.Item>
          {/* <Menu.Item>
            <Button
              key="6"
              style={{ marginTop: 20, width: "10.8rem" }}
              onClick={() => setPayModalVisible(true)}
              bodyStyle={{paddingRight: "40px"}}
              disabled={record.status !== "ready"}
            >
              Pay
            </Button>
          </Menu.Item> */}
          <Menu.Item  key={5} >
            <Button
              key="6"
              // style={{ marginTop: 20 }}
              icon={<DeliveredProcedureOutlined />}
              onClick={() => setRequestDeliveryModalOpen(record)}
              disabled={record.status !== "paid"}
            >
              Request Delivery
            </Button>
          </Menu.Item>
          <Menu.Item key={6}>
            <Button
              key="6"
              // style={{ marginTop: 20 }}
              icon={<Icon type="mail" />}
              onClick={() => {
                setOpenMessageModal(true);
                setProductModalVisible(false)
              }}
            >
              Message Customer
            </Button>
          </Menu.Item>
        </Menu>
      );
  };

  useEffect(()=>{
    
    if (orderData && orderData.getOrders && orderData.getOrders.length > 0) {
      for (let i of orderData.getOrders) {

        let customer = allCustomers.filter((x) => x.user_id === i.user_id);
        allProducts && setProductToDisplay(()=>{
          return allProducts.filter((x) => x.id === i.product_id);
        });

        allCustomers && setCustomerToDisplay(()=>{
          return allCustomers.filter((x) => x.user_id === i.user_id);
        });

        // for (let j of i.product_id) {
        //  console.log("to check", j);
        // const good = 
          
        // }
        // orderArray.push({ ...i, products, customer });
        setOrderArray(()=>{
          return {
            ...i,
            productToDisplay,
            // customer,
            customerToDisplay,
          }
        })

      }
    }
  }, [allProducts, allCustomers]);
  

  const displayProducts = (order_items, products) => {
    // console.log("orderItems", order_items);
    return order_items.map((order_item, i) => {
      return(
      <Card hoverable>
        <Row>
          <Col span={4}>
            {products[i] && <Avatar shape="square" src={products[i].picture || products[i].image > 0 && products[i].image[0] || ""} />}
          </Col>
          <Col span={12}>
            <Paragraph>
              {products[i] && <strong>{products[i].name}</strong>}<br />
              {products[i] && products[i].variants && products[i].variants.length > 0 && <strong>{ products[i].variants[0].value}</strong>}
              {products[i] && products[i].custom_quantities && products[i].custom_quantities.length > 0 && <strong>{ products[i].custom_quantities[0].name}</strong>}
              {products[i] && products[i].products && products[i].products.length > 0 &&  products[i].products.map((pop, ind)=><strong style={{display: "inline-block"}}>{ pop.name} {products[i].quantity[ind]} unit(s)</strong>)}
            </Paragraph>
            <Paragraph>
              <strong>Qty: </strong> {order_item.quantity}{" "}
              <strong> Amt: </strong> {order_item.amount || order_item.price}
            </Paragraph>
          </Col>
        </Row>
      </Card>
    )});
  };

  const columns = [
    {
      title: strings.customer,
      dataIndex: "customer",
      key: "customer",
      render: (customer) => {
        if (customer) {
          return customer && (customer?.first_name + " " +customer?.last_name);
        } else return "N/A";
      },
    },
    {
      title: strings.total,
      dataIndex: "order_items",
      key: "order_items",
      render: (items) => calculateTotal(items),
    },
    {
        title: strings.customerID,
        dataIndex: "customer",
        key: "customer",
        render: (customer) => {
          return customer?.user_id || customer?.id
        }
    },
    {
      title: strings.status,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "pending":
            color = "red";
            break;
          case "accepted":
            color = "blue";
            break;
          case "completed":
            color = "green";
            break;
          default:
            color = "red";
            break;
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: strings.createdDate,
      dataIndex: "created_at",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      key: Math.random(),
      render: (record) =>
        record ? (
          <span>{moment(record).format("YYYY-MM-DD")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
    {
      title: strings.createdTime,
      dataIndex: "created_at",
      key: Math.random(),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      render: (record) =>
        record ? (
          <span>{moment(record).format("hh:mm:ss A")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
  ];

  const calculateTotal = (order_items) => {
    let total = 0;
    for (let item of order_items) {
      total += +Number(item.amount);
    }
    return total;
  };

  const normalStyle = {
    margin: "5px 10px 15px 15px",
    paddingBottom: "10px",
    cursor: "pointer"
  }

  const aciveStyle = {
    // textDecoration: "underline",
    color: "#D90068",
    margin: "5px 10px 15px 15px",
    paddingBottom: "10px",
    borderBottom: "1px solid #D90068",
    cursor: "pointer"
  }

  //columns for the instore orders from graphQl/////////////////////////////////////////
  const inStorecolumns = [
    {
      title: strings.customer,
      dataIndex: "customer",
      key: "1",
      render: (customer) => {
        if (customer) {
          return customer && (customer.first_name + " " +(customer.last_name ? customer.last_name : ""));
        } else return "N/A";
      },
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters})=>{
        return <><Input 
                  autoFocus 
                  placeholder="type text here" 
                  value={selectedKeys[0]}
                  onChange={(e)=>{
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({closeDropdown: false})
                  }}
                  onPressEnter={()=>{
                    confirm()
                  }}
                  onBlur={()=>{
                    confirm()
                  }}
                  style={{width:"9.4rem"}}
                  ></Input>
                  <div style={{display: "flex", gap: "0.5px", marginTop: "2px"}}>
                  <Button onClick={()=>confirm()} type="primary">Search</Button>
                  <Button onClick={()=>clearFilters()} type="primary">Reset</Button>
                  </div>
                  </>
      },
      filterIcon: ()=>{
        return <SearchOutlined />
      },
      onFilter: (value, record) =>{
        return record.customer.first_name.toLowerCase().includes(value.toLowerCase());
      }
    },
    {
      title: strings.phoneNumber,
      dataIndex: "customer",
      key: "2",
      render: (customer) => {
        if (customer) {
          return customer && (customer.phone_number);
        } else return "N/A";
      },
    },
    {
      title: strings.orderId,
      dataIndex: "order_id",
      key: "3",
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters})=>{
        return <><Input 
                  autoFocus 
                  placeholder="type text here" 
                  value={selectedKeys[0]}
                  onChange={(e)=>{
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({closeDropdown: false})
                  }}
                  onPressEnter={()=>{
                    confirm()
                  }}
                  onBlur={()=>{
                    confirm()
                  }}
                  style={{width:"9.4rem"}}
                  ></Input>
                  <div style={{display: "flex", gap: "0.5px", marginTop: "2px"}}>
                  <Button onClick={()=>confirm()} type="primary">Search</Button>
                  <Button onClick={()=>clearFilters()} type="primary">Reset</Button>
                  </div>
                  </>
      },
      filterIcon: ()=>{
        return <SearchOutlined />
      },
      onFilter: (value, record) =>{
        return record.order_id.includes(value);
      }
    },
    {
      title: strings.status,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "pending":
            color = "red";
            break;
          case "processing":
            color = "orange";
            break;
          case "ready":
            color = "blue";
            break;
          case "paid":
            color = "green";
          break;
          case "cancelled":
            color = "gold";
          break;
          default:
            color = "white";
            break;
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters:[
        {text: "pending", value: "pending"},
        {text: "processing", value: "processing"},
        {text: "ready", value: "ready"},
        {text: "paid", value: "paid"},
        {text: "cancelled", value: "cancelled"}
      ],
      onFilter: (value, record )=>{
        return record.status === value
      }
    },
    // {
    //   title: "Order Note",
    //   dataIndex: "orderNotes",
    //   key: "10",
    // },
    {
      title: strings.total,
      dataIndex: "total",
      key: "5",
      render: (total) => {
        if(user?.employer){
          return total.toFixed(2)  +" "+ user?.employer?.currency
        }else{
          return total.toFixed(2)  +" "+ user?.currency
        } 
      }
    },
    {
      title: strings.processedBy,
      dataIndex: "processed_by",
      key: "6",
      render: (processed_by) => {
        let color;
        switch (processed_by) {
          case "Admin":
            color = "blue"
            break;
          default:
            color="green"
            break;
        }
        return (
          <Tag color={color} key={processed_by}>
            {processed_by}
          </Tag>
        );
      },
    },
    // {
    //   title: strings.referenceCode,
    //   dataIndex: "reference_code",
    //   key: "7",
    //   render: (reference_code)=>{
    //     return reference_code === "" ? "-" : reference_code
    //   }
    // },
    {
      title: strings.createdDate,
      dataIndex: "created_at",
      // defaultSortOrder: "ascend",
      // sorter: (a, b) =>
      //   new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      // key: Math.random(),
      render: (created_at) =>
        created_at ? (
          <span>{moment(created_at).format("YYYY-MM-DD")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
    {
      title: strings.createdTime,
      dataIndex: "created_at",
      key: Math.random(),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
      render: (created_at) =>
      created_at ? (
          <span>{moment(created_at).format("hh:mm:ss A")}</span>
        ) : (
          <span>N/A</span>
        ),
    },
  ];

  const handleInstore =()=>{
    setOnPointOrders(true);

  }

  //to handle the click of the row in store////////////////////////////////////
  const handleRowClick = (data) =>{
    itemData && props.getOrderedItems(data, itemData);
    // console.log(data);
   
      orders && openProductModal(data);

    
  }

  // console.log("orders",orders);

  const subPlan = user.employer ? user.employer.subscription_plan : user.subscription_plan;
  const { Paragraph, Title } = Typography;

  return (
    <main>
    <div style={{display: "flex"}}>
   
      <Paragraph level={4} strong style={OnPointOrders ? aciveStyle : normalStyle} onClick={()=> setOnPointOrders(true)}>
        {strings.onPoint}
      </Paragraph>
      {/* <Paragraph level={4} strong style={OnPointOrders === false ? aciveStyle : normalStyle} onClick={()=>setOnPointOrders(false)}>
        {strings.onlineOrders}
      </Paragraph> */}
    </div>
      {!OnPointOrders 
        &&  
        <>
        <Paragraph style={{ marginLeft: "10px" }}>
          {strings.totalonline}: {allOrders?.length}
        </Paragraph>
        
        <Table
          dataSource={allOrders}
          columns={columns}
          loading={loading}
          onRow={(data) => ({ onClick: () => openProductModal(data) })}
        />
      </>}
      {//Data for the on point orders//////////////////////////////////////////////////
        OnPointOrders &&
      <> 
        <Paragraph style={{ marginLeft: "10px" }}>
        {strings.totalpoint}: {savedOrders && savedOrders && savedOrders?.length}
      </Paragraph>
      {props.isKitchenScreenActive 
        && 
        <Paragraph>
          <Button
            type="primary"
            onClick={()=>{
              // props.activateKitchenScreen(true)
              if(subPlan !== "Pro"){
                return Modal.info({
                  title: "You need to be on Pro plan to display screen"
                })
              }
              window.open("/loystar-kds", "_blank", "fullscreen=yes,titlebar=yes,toolbar=yes,menubar=yes,resizable=yes")
            }}
          >
            View Kitchen Screen
          </Button>
      </Paragraph>}
      <Table
        dataSource={savedOrders && savedOrders}
        columns={inStorecolumns}
        loading={loading}
        onRow={(data) => ({ onClick: () => handleRowClick(data) })}
        // sortDirections=["descend"]
      /></>
      }
      {!!requestDeliveryModalOpen && (
        <RequestDeliveryModal
          open={!!requestDeliveryModalOpen}
          onCancel={() => setRequestDeliveryModalOpen(null)}
          user={props.user}
          customer={requestDeliveryModalOpen.customer}
          // cartList={requestDeliveryModalOpen.products}
          cartList={orders}
        />
      )}
      <Modal
        title={strings.payOrder}
        open={payModalVisible}
        onOk={()=>handleCheckoutToCart()}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setPayModalVisible(false);
          setConfirmLoading(false);
        }}
      >
        <p>{strings.doYouWantToPayOrder}</p>
      </Modal>
      <Modal
        title={strings.confirmPayment}
        open={confirmPaymentVisible}
        onOk={()=>handlePayOrderbyGraph(orderID)}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmPaymentVisible(false);
          setConfirmLoading(false);
        }}
      >
        <p>{strings.doYouWantToConfirmPayment}</p>
      </Modal>
      <Modal
        title={strings.confirmOrder}
        open={confirmModalVisible}
        onOk={()=>handleConfirmOrderbyGraph(orderID)}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmModalVisible(false);
          setConfirmLoading(false);
        }}
      >
        <p>{strings.doYouWantToAcceptOrder}</p>
      </Modal>
      <Modal
        title={strings.processOrder}
        open={processModalVisible}
        onOk={()=>handleProcessOrderbyGraph(orderID)}
        // onOk={handleCancelOrder}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setProcessModalVisible(false);
          setConfirmLoading(false);
        }}
      >
        <p>{strings.doYouWantToProcessOrder}</p>
      </Modal>
      <Modal
        title={strings.cancelOrder}
        open={cancelModalVisible}
        onOk={()=>handleCancelOrderbyGraph(orderID)}
        // onOk={handleCancelOrder}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setCancelModalVisible(false);
          setConfirmLoading(false);
        }}
      >
        <p>{strings.doYouWantToCancelOrder}</p>
      </Modal>
     
      <Modal
        title={strings.orderDetails}
        open={productModalVisible}
        style={{marginTop: "-6.4rem"}}
        onOk={() => setProductModalVisible(false)}
        onCancel={() => {
          setProductModalVisible(false);
        }}
        footer={[
          <Button key="back" onClick={() => setProductModalVisible(false)}>
            {strings.cancel}
          </Button>,
          <Dropdown overlay={menu(currentOrder)} placement="topCenter">
            <Button key="submit" type="primary">
              {strings.actions}
            </Button>
          </Dropdown>,
        ]}
      >
        {currentOrder && (
          <>
            <Paragraph>
              <strong>{strings.customerName}: </strong>
              {(currentOrder.customer.first_name
                ? currentOrder.customer.first_name
                : "") +
                " " +
                (currentOrder.customer.last_name
                  ? currentOrder.customer.last_name
                  : "")}
            </Paragraph>
            <Paragraph>
              <strong>{strings.orderCreatedAt}: </strong>
              {moment(currentOrder.created_at).format("DD-MM-YYYY")}
            </Paragraph>
            <Paragraph>
              <strong>{strings.createdTime}: </strong>
              {moment(currentOrder.created_at).format("hh:mm:ss A")}
            </Paragraph>
            <Paragraph>
              <strong>{strings.products} </strong>
              {!isItFetched ? <Spin /> : <div
                className="productextra"
              >{displayProducts(orders, orders)}</div>}
            </Paragraph>
            <Paragraph>
              <strong>{strings.description}: </strong>
              {orders ? orders.description : "N/A"}
            </Paragraph>
            <Paragraph>
              <strong>Order Note: </strong>
              {orders ? currentOrder.orderNotes : "N/A"}
            </Paragraph>
            <Paragraph>
              <strong>Customer Email: </strong>
              {orders ? currentOrder.customer.email : "N/A"}
            </Paragraph>
            <Paragraph>
              <strong>Delivery Address: </strong>
              {orders ? currentOrder.customer.delivery_address : "N/A"}
            </Paragraph>
            <Paragraph style={{ textTransform: "capitalize" }}>
              <strong>{strings.status}: </strong>
              {currentOrder.status}
            </Paragraph>
            <Paragraph>
              <strong>{strings.total}: </strong>
              {OnPointOrders ? currentOrder.total.toFixed(2) : calculateTotal(currentOrder.order_items)} {user?.employer?.currency || user?.currency} 
            </Paragraph>
          </>
        )}
      </Modal>
      
      {openMessageModal && 
      <ShortSMSModal 
        showModal={openMessageModal}
        closeModal={()=>setOpenMessageModal(false)}
        customer={currentOrder && currentOrder.customer}
        smsBalance={smsBalance}
      />}
      
    </main>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  branch: state.branch.allBranches,
  error: state.error,
  merchant_details: state.merchant_details,
  order_items: state.orderItems.order_items,
  isItFetched: state.orderItems.isItFetched,
  orders: state.orders,
  loyaltyProgram: state.loyaltyProgram,
  isSubscribedToMultiLevel: state.multiLevel.isSubscribed,
  user: state.auth.user || state.merchant.user,
  isOrderPaid: state.allOrders.isOrderPaid,
  paidID: state.allOrders.paidID,
  isKitchenScreenActive: state.appStore.isKitchenScreenActive
});

export default connect(mapStateToProps, {
  getAllOrders,
  getAllCustomers,
  getAllProducts,
  updateOrder,
  cancelOrder,
  getMerchantByDispatch,
  getOrderedItems,
  getOrder,
  recordSale,
  displayOrder,
  payOrder,
  activateKitchenScreen
})(WithApolloClient(Orders));
