import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import Products from "./Layouts/Products";
import SidebarMenuItems from "./components/SidebarMenuItems";
import Branches from "./Layouts/Branches";
import Customers from "./Layouts/Customers";
import Invoices from "./Layouts/Invoices";
import Reports from "./Layouts/Reports";
import TodayReports from "./Layouts/Reports/TodayReports";
import { setNetworkStatus } from "../../redux/actions/networkStatusActions";
import { getAllBusinessBranches } from "../../redux/actions/branchActions";
import { toggleRenewalModal, checkSubscriptionStatus, allowToSub } from "../../redux/actions/renewalActions";
import BirthdayAndOffers from "./Layouts/BirthdayAndOffers";
import OnlineIndicator from "../../components/OnlineIndicator";
import RenewalModal from "../Sales/_partials/RenewalModal";
import LoyaltyPrograms from "./Layouts/LoyaltyPrograms";
import Account from "./Layouts/Account";
import { MultiLevelLoyalty } from "./Layouts/Apps";
import AppStore from "./Layouts/Integrations";
import SupplyHistory from "./Layouts/SupplyHistory";
import Disbursements from "./Layouts/Disbursements";
import Offline_Draft from "./Layouts/Offline_Draft";
import OrderMenu from "./Layouts/OrderingMenu";
import FormEntry from "./Layouts/FormEntry";
import CustomFields from "./Layouts/CustomFields";
import Services from "./Layouts/Appointments/_partials/Services";
import Bookings from "./Layouts/Appointments/_partials/Bookings";
import CalendarView from "./Layouts/Appointments/_partials/Calendar";
import ReferralPrograms from "./Layouts/ReferralPrograms";
import Settings from "./Layouts/Appointments/_partials/Settings";
import { fetchActiveApps } from "../../redux/actions/appActions";
// UI
import "./index.scss";
import strings from "../../strings";
import Requests from "./Layouts/Requests";
import Redemption from "./Layouts/Redemption";
import axios from "axios";
import Leyjahs from "./Layouts/Leyjah";

const { Content, Footer, Sider } = Layout;

const milisecondsToDaysConst = 86400000;

const Dashboard = ({
  user,
  isRenewalModalOpen,
  setNetworkStatus,
  getAllBusinessBranches,
  toggleRenewalModal,
  allBranches,
  // checkSubscriptionStatus,
  subscriptionAccessStatus,
  allowToSub
}) => {
  const client = useApolloClient();
  const [ isSubscription, setIsSubscription ] = useState(true);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const isAdminIsManager =
      user.role_id === 1 || (user.role && user.role.id === 2);

    if (isAdminIsManager && allBranches.length === 0) {
      getAllBusinessBranches();
    }

    window.addEventListener("online", () => {
      setNetworkStatus(navigator.onLine);
    });
    window.addEventListener("offline", () => {
      setNetworkStatus(navigator.onLine);
    });

    // Redirect cashier to Sales screen
    // if (user.role && user.role.id === 3) {
    //   history.push("/sales");
    // }

    return () => {
      window.removeEventListener("online", () => {
        setNetworkStatus(navigator.onLine);
      });
      window.removeEventListener("offline", () => {
        setNetworkStatus(navigator.onLine);
      });
    };
  }, [user, setNetworkStatus, allBranches.length, getAllBusinessBranches]);


  useEffect(() => {
    if(subscriptionAccessStatus) return setIsSubscription(true);

    !subscriptionAccessStatus && checkSubscriptionStatuss();
  }, [subscriptionAccessStatus])

  const checkSubscriptionStatuss = async () => {
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/subscription-status`, { merchant_id: user.id.toString() }, {
        headers: {
            ["Access-Control-Allow-Origin"]: "*",
            ["multi-level-token"]: "",
            ["user-auth"]: "",
        }
    })
    .then((res)=>{
      setLoading(false);
      if(res.data.subscription.length > 0){
        const subscription = res.data.subscription[0];
        if(subscription.status === "active"){
          setIsSubscription(true);
          allowToSub(true);
        }else{
          setIsSubscription(true);
          allowToSub(true);
        }
      }else{
        setIsSubscription(false);
        allowToSub(false);
      }
    })
    .catch((err)=>{
      setLoading(false);
      console.log(err);
    })
  }
  

  const isAdminIsManager =
    user.role_id === 1 || (user.role && user.role.id === 2);

  const business = user.employer ? { ...user.employer } : { ...user };

  // if(business.subscription_plan === "Free"){
  //   return <Redirect to="/sales" />;
  // }

  // if (business.subscription_plan !== "Free") {
    const daysLeft = Math.ceil(
      (new Date(business.subscription_expires_on) - Date.now()) /
        milisecondsToDaysConst
    );

    if (daysLeft <= 0 && !loading && !isSubscription) {
      return <Redirect to="/sales" />;
    }
  // }

  return (
    <Layout>
      {/* Sidebar */}
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        width="250"
        style={{
          backgroundColor: "#fff",
          minHeight: "100vh",
        }}
        className="sidebar"
      >
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <OnlineIndicator />
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="logo">
            <img src="/assets/img/loystar-logo.png" alt="App Logo" />
          </div>
          {user.role ? (
            <h5>{user.username}</h5>
          ) : (
            <h5>
              {user.first_name} {user.last_name}
            </h5>
          )}
          <span className="user-role-tag">
            {!user.role ? strings.admin : strings[user.role.name]}
          </span>
        </div>
        <SidebarMenuItems
          user={user}
          allBranches={allBranches || []}
          // match={match}
        />
      </Sider>
      {/* =====Sidebar */}
      <Layout>
        {/* Main Content */}
        <Content style={{ backgroundColor: "#f9f9f9" }}>
          {isAdminIsManager ? (
            <>
              <Route
                exact
                path="/d/branch-products/:branchId?"
                component={Products}
              />
              <Route path="/d/products" component={Products} />
              <Route path="/d/branches" component={Branches} />
              <Route path="/d/customers" component={Customers} />
              <Route path="/me" component={Customers} />
              <Route path="/d/transactions/reports" component={Reports} />
              <Route path="/d/referral_programs" component={ReferralPrograms} />

              <Route
                path="/d/transactions/today/report"
                component={TodayReports}
              />
              <Route
                path="/d/transactions/redemption"
                component={Redemption}
              />
              <Route
                path="/d/birthdayandoffers"
                component={BirthdayAndOffers}
              />
            </>
          ) : (
            <>
              <Route path="/d/customers" component={Customers} />

              <Route
                exact
                path="/d/transactions/today/report"
                component={TodayReports}
              />
              <Route path="/d/transactions/reports" component={Reports} />
            </>
          )}
          <Route path="/d/supply-history" component={SupplyHistory} />
          <Route path="/d/upay-disbursements" component={Disbursements} />

          <Route path="/d/transactions/invoices" component={Invoices} />

          <Route path="/d/transactions/draft-sales" component={Offline_Draft} />
          <Route
            path="/d/transactions/offline-sales"
            component={Offline_Draft}
          />
          <Route path="/d/delivery-requests" component={Requests} />

          <Route path="/d/orders" component={OrderMenu} />
          <Route path="/d/loyalty_programs" component={LoyaltyPrograms} />

          <Route path="/d/account" component={Account} />
          <Route path="/d/accounting" component={Leyjahs} />

          <Route path="/d/appointments/bookings" component={Bookings} />
          <Route path="/d/appointments/calendar" component={CalendarView} />

          <Route path="/d/appointments/settings" component={Settings} />
          <Route path="/d/appointments/services" component={Services} />

          <Switch>
            <Route path="/d/app_store" component={AppStore} />

            <Route path="/d/apps/multi-level" component={MultiLevelLoyalty} />
            <Route path="/d/apps/custom-fields" component={CustomFields} />
            <Route path="/d/forms" component={FormEntry} />
          </Switch>

          {/* =====Renewal Modal===== */}

          {isRenewalModalOpen ? (
            <RenewalModal
              isRenewalModalOpen={isRenewalModalOpen}
              toggleRenewalModal={toggleRenewalModal}
              client={client}
            />
          ) : null}
        </Content>
        {/* =====Main Content */}

        {/* Footer */}
        <Footer style={{ textAlign: "center", backgroundColor: "#f9f9f9" }}>
          Loystar™ - {strings.allRightsReserved}. © 2016 - 2024
        </Footer>
        {/* =====Footer */}
      </Layout>
    </Layout>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user || state.merchant.user,
  allBranches: state.branch ? state.branch.allBranches : [],
  isRenewalModalOpen: state.renewal.isRenewalModalOpen,
  subscriptionAccessStatus: state.renewal.subscriptionAccessStatus,
  subscriptionStatus: state.renewal.subscriptionStatus,
});

export default connect(mapStateToProps, {
  setNetworkStatus,
  getAllBusinessBranches,
  toggleRenewalModal,
  checkSubscriptionStatus,
  fetchActiveApps,
  allowToSub
})(withRouter(Dashboard));
