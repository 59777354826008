import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Radio, Modal, Spin, Button, Popover, message, Tooltip } from "antd";
import {
  getPricingPlanData,
  addMerchantCurrentPlan,
} from "../../../../redux/actions/merchantAction";
import { allowToSub } from "../../../../redux/actions/renewalActions";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import { signoutUser } from "../../../../redux/actions/authActions";
import paymentLogo from "../../../../icons/badges.png";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { usePaystackPayment } from "react-paystack";
import BasicPaymentDetails from "./BasicPaymentDetails";
import ProPaymentDetails from "./ProPaymentDetails";
import Upgrade from "./Upgrade";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { fetchActiveApps } from "../../../../redux/actions/appActions";
import { useHistory } from "react-router-dom";

import "./index.scss";
import Duration from "./Duration";
import strings from "../../../../strings";

const FLUTTERWAVE_PUBLIC_KEY = process.env.REACT_APP_FLUTTERWAVE_KEY;
const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_KEY;
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;

const milisecondsToDaysConst = 86400000;

const RenewalModal = ({
  isRenewalModalOpen,
  toggleRenewalModal,
  getPricingPlanData,
  addMerchantCurrentPlan,
  getAllBusinessBranches,
  signoutUser,
  allBranches,
  client,
  user,
  fetchActiveApps,
  isFromSales,
  allowToSub
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const [staffData, setStaffData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [ecommerceData, setEcommerceData] = useState({});
  const [staffs, setStaffs] = useState([]);
  const [duration, setDuration] = useState(0);
  const [total, setTotal] = useState(0);
  const [metaInfo, setMetaInfo] = useState({});
  const [paymentOptions, setPaymentOptions] = useState("");
  const [upgradePlan, setUpgradePlan] = useState("");
  const [openPaypalModal, setOpenPaypalModal] = useState(false);
  const [numOfStaffs, setNumOfStaffs] = useState(0);
  const [numOfBranches, setNumOfBranches] = useState(0);
  const [ activePaidApps, setActivePaidApps ] = useState([]);
  const [ totalAppsPrice, setTotalAppsPrice ] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    getAllBusinessBranches();

    const getPlanData = [
      getPricingPlanData({
        plan_name: user.employer ? user.employer.subscription_plan : user.subscription_plan,
      }),
      getPricingPlanData({
        plan_name: "Staff",
      }),
      getPricingPlanData({
        plan_name: "Branch",
      }),
      getPricingPlanData({
        plan_name: "Ecommerce",
      }),
    ];

    Promise.all(getPlanData)
      .then(([plan, staff, branch, ecommerce]) => {
        setIsLoading(false);

        setPlan(plan.data);
        setStaffData(staff.data);
        setBranchData(branch.data);
        setEcommerceData(ecommerce.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(()=>{
    getActiveApps();
  }, [])

  useEffect(() => {
    if (allBranches.length && plan) {
      // Filter Branches that are not Ecommerce
      const filteredBranches = allBranches.filter(
        (branch) => branch.name !== "Ecommerce"
      );

      const staffs = filteredBranches.flatMap((branch) => {
        return branch.staff;
      });

      setStaffs(staffs);
    }
  }, [allBranches, plan]);

  const config = {
    public_key: FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: total,
    currency: user.currency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user.email,
      phonenumber: user.contact_number,
      name: `${user.first_name}${user.last_name}`,
    },
    meta: metaInfo,
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: user.email,
    currency: user.currency,
    amount:
      user.currency === "USD" || user.currency === "GBP"
        ? ((Number(total) * 100) / nairaDollarRate).toFixed(2)
        : Number(total) * 100,
    publicKey: PAYSTACK_PUBLIC_KEY,
  };

  const convertToDollar = (amount) => {
    return (Number(amount) / nairaDollarRate).toFixed(2);
  }

  const getActiveApps = () => {
    fetchActiveApps()
    .then((res)=>{
      const appsToPayFor = res.data.filter(app => app.app.cost > 0);

      const totalAppsCost = appsToPayFor.reduce((prev, curr)=>{
        return prev + Number(curr.app.cost);
      }, 0);
      
      setActivePaidApps(appsToPayFor);
      setTotalAppsPrice(user.currency === "NGN" ? totalAppsCost : convertToDollar(totalAppsCost));
    })
    .catch((err)=>{

    })
  }

  const handleFlutterPayment = useFlutterwave(config);
  const handlePaystackPayment = usePaystackPayment(paystackConfig);

  const updateMetaInfo = (info) => {
    setMetaInfo({
      ...info,
      plan: paymentOptions === "renewal" ? user.subscription_plan : upgradePlan,
    });
  };

  const PaymentDetails =
    user.subscription_plan === "Pro" ? (
      <ProPaymentDetails
        plan={plan}
        staffData={staffData}
        branchData={branchData}
        ecommerceData={ecommerceData}
        allBranches={allBranches}
        staffs={staffs}
        duration={Number(duration)}
        setTotal={setTotal}
        paymentOptions={paymentOptions}
        updateMetaInfo={updateMetaInfo}
        activePaidApps={activePaidApps}
        user={user}
        totalAppsPrice={totalAppsPrice}
      />
    ) : (
      <BasicPaymentDetails
        plan={plan}
        staffs={staffs}
        staffData={staffData}
        duration={Number(duration)}
        setTotal={setTotal}
        paymentOptions={paymentOptions}
        updateMetaInfo={updateMetaInfo}
        activePaidApps={activePaidApps}
        user={user}
        totalAppsPrice={totalAppsPrice}
      />
    );

  const handlePaymentOptionChange = (e) => {
    setPaymentOptions(e.target.value);
    setDuration(0);
    setTotal(0);
  };

  const handleDurationChange = (val) => {
    // setDuration(val >= 12 ? val - 2 : val);
    let n_val = parseInt(val, 10);

    if (user.subscription_plan === "Pro") {
      if (n_val === 12) {
        setDuration(n_val - 2);
      } else if (n_val === 18) {
        setDuration(n_val - 3);
      } else if (n_val === 24) {
        setDuration(n_val - 5);
      } else {
        setDuration(n_val);
      }
    } else {
      setDuration(n_val);
    }
  };

  const daysLeft = Math.ceil(
    (new Date(user.subscription_expires_on) - Date.now()) /
      milisecondsToDaysConst
  );

  const isAdmin = user.role_id && user.role_id === 1

  // console.log(staffs, staffData, allBranches, branchData);
  const currentDate =
    user.subscription_plan === "Free"
      ? new Date(Date.now())
      : daysLeft <= 0
      ? new Date(Date.now())
      : new Date(user.subscription_expires_on);

      const numberOfMonthsFromNow =
      Number(currentDate.getMonth()) + Number(duration);

    const expiresOn = new Date(
      currentDate.setMonth(numberOfMonthsFromNow)
    );
    const NOC = expiresOn.toLocaleDateString()

    const noOfDays = (expiresOn.getTime() - new Date(user.subscription_expires_on).getTime()) / milisecondsToDaysConst; 
  
  const paymentCallback = (response) => {
    const numberOfMonthsFromNow =
      Number(currentDate.getMonth()) + Number(duration);

    const expires_on = new Date(
      currentDate.setMonth(numberOfMonthsFromNow)
    ).toLocaleDateString();

    const dateSplits = expires_on.split("/");

    const [dd, mm, yy] = dateSplits;
    const orderedDate = `${dd}/${mm}/${yy}`;
    closePaymentModal();
    // console.log("df",response);
    let trans_ref;
    if(response.tx_ref){
      trans_ref = response.tx_ref
    }else if(response.trxref){
      trans_ref = response.trxref
    }else if(response.purchase_units[0].payments.captures[0].id){
      trans_ref = response.purchase_units[0].payments.captures[0].id
    }

    addMerchantCurrentPlan(user.email, {
      plan: !upgradePlan ? user.subscription_plan : upgradePlan,
      currency_code: user.currency,
      no_of_days: noOfDays,
      expires_on: NOC,
      transaction_reference: `${trans_ref}_no_of_staff:${numOfStaffs}_no_of_branch:${numOfBranches}_payee_email_address:${response?.purchase_units ? response?.purchase_units[0]?.payee?.email_address : user?.email}`,
    }).then((res) => {
      toggleRenewalModal();

      Modal.success({
        title: `${strings.yourAccountHasBeen} ${
          !upgradePlan ? strings.renewed : strings.upgraded
        } ${strings.successfully}.`,
        content: "You will be logged out. Please log back in continue enjoying the service"
      });
      setTimeout(() => {
        signoutUser(client);
      }, 3000);
    });
  };

  const africanCurrency = [
    "DZD",
    "AOA",
    "XOF",
    "BWP",
    "NOK",
    "XOF",
    "BIF",
    "XAF",
    "CVE",
    "KMF",
    "CDF",
    "DJF",
    "EGP",
    "ERN",
    "ETB",
    "GMD",
    "GHS",
    "GNF",
    "KES",
    "LSL",
    "LRD",
    "LYD",
    "MGA",
    "MWK",
    "MRO",
    "MUR",
    "MAD",
    "MZN",
    "NAD",
    "NGN",
    "RWF",
    "SCR",
    "SLL",
    "SOS",
    "ZAR",
    "SDG",
    "SZL",
    "TZS",
    "TND",
    "UGX",
    "ZMK",
    "ZWL"
  ];

  const ifAfricanCurrency = africanCurrency.includes(user.currency);
  // console.log(ifAfricanCurrency);
  const actContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* {ifAfricanCurrency && <Button
        key="flutterwave"
        onClick={() => {
          handleFlutterPayment({
            callback: paymentCallback,
            onClose: () => {},
          });
        }}
      >
        Pay With Flutterwave
      </Button>} */}
      {ifAfricanCurrency && <Button
        key="paystack"
        onClick={() => {
          handlePaystackPayment(paymentCallback, () => {});
        }}
        style={{ marginTop: 5 }}
      >
        Pay With Paystack
      </Button>}
      <Button
        key="paypal"
        onClick={() => setOpenPaypalModal(true)}
        style={{ marginTop: 5 }}
      >
        Pay With Paypal
      </Button>
    </div>
  );
  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {ifAfricanCurrency && <Button
        key="flutterwave"
        onClick={() => {
          handleFlutterPayment({
            callback: paymentCallback,
            onClose: () => {},
          });
        }}
      >
        Pay With Flutterwave
      </Button>}
      {/* {ifAfricanCurrency && <Button
        key="paystack"
        onClick={() => {
          handlePaystackPayment(paymentCallback, () => {});
        }}
        style={{ marginTop: 5 }}
      >
        Pay With Paystack
      </Button>} */}
      <Button
        key="paypal"
        onClick={() => setOpenPaypalModal(true)}
        style={{ marginTop: 5 }}
      >
        Pay With Paypal
      </Button>
    </div>
  );

  const paypalAmount =
    user.currency !== "NGN" ? total : (total / nairaDollarRate).toFixed(2);

  return (
    <Modal
      title={`${
        user.subscription_plan === "Free" ? strings.renew : strings.upgrade
      } ${strings.yourSubscription}`}
      open={isRenewalModalOpen}
      onCancel={toggleRenewalModal}
      footer={[
        <Button onClick={toggleRenewalModal} type="ghost">
          Cancel
        </Button>,
        <Button type="primary" style={{ marginLeft: 10 }} disabled={total <= 1}>
        <Popover key="actions" title="Manually renew to continue your subscription" content={actionsContent}>
          Pay
        </Popover>
      </Button>,
        isFromSales &&  isAdmin && user.currency !=="NGN" &&
        <Tooltip title="Automatically renews my subscription when due">
          <Button 
            type="primary" 
            style={{ marginLeft: 10 }} 
            // disabled={total <= 1}
            onClick={()=>{
              allowToSub(true);
              setTimeout(()=>{
                history.push("/d/account#subscribe");
                // setOpenModal(false);
              }, 1000);
            }}
          >
            Subscribe
          </Button>
        </Tooltip>
      ]}
    >
      <Modal
        title="Pay With Paypal"
        open={openPaypalModal}
        onCancel={() => setOpenPaypalModal(false)}
        footer={[
          <Button onClick={() => setOpenPaypalModal(false)}>Cancel</Button>,
        ]}
      >
        <div style={{ marginTop: 5 }}>
          <PayPalScriptProvider
            options={{
              "client-id": PAYPAL_CLIENT_ID,
              currency: user.currency === "NGN" ? "USD" : user.currency,
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: paypalAmount,
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  setOpenPaypalModal(false);
                  const name = details.payer.name.given_name;
                  message.success(`Transaction completed by ${name}`);
                  paymentCallback(details);
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      </Modal>
      <p>
        {strings.youAreCurrentlyEnrolledOnA}{" "}
        <strong>{user.subscription_plan}</strong> {strings.plan}.
      </p>

      {user.subscription_plan === "Free" ? (
        <Upgrade
          getPricingPlanData={getPricingPlanData}
          setTotal={setTotal}
          duration={duration}
          setDuration={setDuration}
          upgradePlan={upgradePlan}
          setUpgradePlan={setUpgradePlan}
          staffs={staffs}
          setNumOfStaffs={setNumOfStaffs}
          numOfStaffs={numOfStaffs}
          numOfBranches ={numOfBranches} 
          setNumOfBranches={setNumOfBranches}
          paymentOptions={"upgrade"}
          updateMetaInfo={updateMetaInfo}
        />
      ) : (
        <div className="renewal-wrapper">
          <div className="payment-option">
            <p>{strings.pleaseChooseAPaymentOption}</p>
            <Radio.Group
              onChange={handlePaymentOptionChange}
              value={paymentOptions}
            >
              <Radio value="renewal">{strings.planRenewal}</Radio>
              {user.subscription_plan !== "Pro" && (
                <Radio value="upgrade">{strings.planUpgrade}</Radio>
              )}
            </Radio.Group>
          </div>

          {paymentOptions === "renewal" ? (
            <div>
              <div className="duration">
                <p>{strings.pleaseSelectDurationToPayFor}:</p>

                <Duration
                  handleDurationChange={handleDurationChange}
                  isLoading={isLoading}
                  user={user}
                />
              </div>

              {isLoading ? (
                <div className="loader">
                  <Spin />
                </div>
              ) : duration > 0 ? (
                PaymentDetails
              ) : null}
            </div>
          ) : paymentOptions === "upgrade" ? (
            <Upgrade
              getPricingPlanData={getPricingPlanData}
              setTotal={setTotal}
              duration={duration}
              setDuration={setDuration}
              setNumOfStaffs={setNumOfStaffs}
              numOfStaffs={numOfStaffs}
              numOfBranches ={numOfBranches} 
              setNumOfBranches={setNumOfBranches}
              upgradePlan={upgradePlan}
              setUpgradePlan={setUpgradePlan}
              staffs={staffs}
              paymentOptions={paymentOptions}
              updateMetaInfo={updateMetaInfo}
            />
          ) : null}
        </div>
      )}
      <img
        style={{ margin: "30px 0 -20px 0" }}
        src={paymentLogo}
        alt="payment options"
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, {
  getPricingPlanData,
  addMerchantCurrentPlan,
  getAllBusinessBranches,
  signoutUser,
  fetchActiveApps,
  allowToSub
})(RenewalModal);
