import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Modal, Input, notification, message as mess, message } from "antd";
import { useApolloClient } from "@apollo/client";
import openInNewTab from "../../../../../../utils/openInNewTab";
import {
  enableAppIntegration,
  disableAppIntegration,
  fetchActiveApps,
} from "../../../../../../redux/actions/appActions";
import {
  changeMultiLevelStatus,
  changeBookingStatus,
} from "../../../../../../redux/actions/multiLevelActions";
import strings from "../../../../../../strings";
import Axios from "axios";
import api from "../../../../../../api";
import { signoutUser } from "../../../../../../redux/actions/authActions";
import { getPricingPlanData } from "../../../../../../redux/actions/merchantAction";
import { activateStripForLoystar, activateStripeLoyalty } from "../../../../../../redux/actions/appActions";
import { createOrganization, loginOrganisation } from "../../../../../../redux/actions/referralProgramAction";
//import translate from "../../../../../../utils/translateBackend";

const ViewDetailsModal = ({
  visible,
  subscribedApps,
  toggleViewDetailsModal,
  currentIntegration,
  enableAppIntegration,
  disableAppIntegration,
  changeMultiLevelStatus,
  user,
  fetchActiveApps,
  signoutUser,
  getPricingPlanData,
  activateStripForLoystar,
  activateStripeLoyalty
}) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [ showNitroMessage, setShowNitroMessage] = useState(false);
  const [ showVpayMessage, setShowVpayMessage] = useState(false);
  const [ nitroLocation, setNitroLocation ] = useState("");
  const [ isNitroLoading, setIsNitroLoading ] = useState(false);
  const [ isSaveVpayLoading, setIsSaveVpayLoading ] = useState(false);
  const [ vPayEmail, setVpayEmail ] = useState("");
  const [ vPayPassword, setVpayPassword ] = useState("");
  const [ vPayPrivk, setVpayPrivk ] = useState("");
  const [ vPayPubk, setVpayPubk ] = useState("");
  const [ showPayStackMessage, setShowPayStackMessage ] = useState(false);
  const [ isPayStackLoading, setIsPayStackLoading ] = useState(false);
  const [ stackToken, setStackToken ] = useState("");
  const [ publicStackToken, setPublicStackToken ] = useState("");
  const [ terminalId, setTerminalId ] = useState("");
  const [ terminalProvider, setTerminalProvider ] = useState("");
  const [ terminalLabel, setTerminalLabel ] = useState("");
  const [ setTerminal, setSetTerminal ] = useState(false);
  const [ isStripeFormOpen, setIsStripeFormOpen ] = useState(false);
  const [ stripeSecretKey, setStripeSecretKey ] = useState("");
  const [ stripeAccountWebhookToken, setStripeAccountWebhookToken ] = useState("");
  const [ stripePublishableKey, setStripePublishableKey ] = useState("");
  const [ showStripDetails, setShowStripDetails ] = useState(false);
  const [ isStripeLoading, setIsStripeLoading ] = useState(false);
  const [ showOrgPassword, setShowOrgPassword ] = useState(false);
  const [ orgPassword, setOrgPassword ] = useState(null);

  const client = useApolloClient();
  const history = useHistory();

  const {
    id,
    website_link,
    base_url,
    icon,
    app_name,
    company_name,
    description,
    cost: price,
  } = currentIntegration;

  

  
  const cantSubcribeTorAppointment = "Free" || "Basic Plus" || "Basic";
  const currencyForStripe = ["GBP", "USD", "EUR", "CAD"]; 
  const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;
          

  const handleSaveVpaySettings = async () => {

    // if(vPayEmail === "" || vPayPassword === "" || vPayPrivk === "" || vPayPubk ===""){
    //   return message.warn("Field should not be empty");
    // }

    setIsSaveVpayLoading(true);
    const data = {
      vpay_email: vPayEmail,
      vpay_password: vPayPassword,
      vpay_privk: vPayPrivk,
      vpay_pubk: vPayPubk
    }

    const res = await api.merchants.saveVpaySettings( data );

    if(res.status === 200 ){
      setIsSaveVpayLoading(false);

      base_url ? await enableVpayApp(id) : openInNewTab(website_link, true);
      
    }
  }

  const handleSetStripeKey = async () => {

    if(
      stripeSecretKey === "" 
        || stripeAccountWebhookToken === "" 
          || stripePublishableKey === "" 
    ){
      return message.warn("Fields should not be empty");
    }

    setIsStripeLoading(true);

    const data = {
      stripe_secret_key: stripeSecretKey, 
      stripe_account_webhook_token: stripeAccountWebhookToken,
      stripe_publishable_key: stripePublishableKey
    }

    const res = await api.merchants.saveStripsDetails(data);

    if(res.status === 200 ){
      setIsStripeLoading(false);
      setShowStripDetails(false);

      Modal.success({
        title: "Stripe loyalty details set successfully",
        onOk: async () => {
          base_url ? await enableStripeLoyaltyApp(id) : openInNewTab(website_link, true);
        }
      });
      
    }else {
      setIsStripeLoading(false);
    }
    
  }

  
  const handlePayStackDetails = async () => {
    setIsPayStackLoading(true);
    if(stackToken === "" || publicStackToken === ""){
      setIsPayStackLoading(false);
      return Modal.error({
        title: "Please set your paystack token"
      });
    }
    const data = {
      paystack_token: stackToken,
      paystack_public_key: publicStackToken
    }

    await api.HttpClient.put("/merchant/update_merchant_paystack_details", { data } )
    .then((res)=>{
      if(res.status === 200){
        setIsPayStackLoading(false);
        Modal.success({
          title: res.data.message,
          onOk: () => {
            setShowPayStackMessage(false);
            base_url ? enablePayStackApp(id) : openInNewTab(website_link, true);
          }
        })
      }
    })
    .catch((err)=>{
      setIsPayStackLoading(false);
      console.log(err);
      Modal.error({
        title: err.message
      })
    })
  };

  const enablePayStackApp = async app_id => {
    setIsLoading(true);
    try {
      const res = await enableAppIntegration(app_id);
      if (res && res.status === 200) {
        // setIsLoading(false);
        Modal.success({
          title: `Successfully enabled paystack. You will be logged out, Kindly log back in to complete setup`,
          onOk: ()=>{
            signoutUser(client);
            // setShowNitroMessage(false);
          }
        })
        
      }
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  const enableVpayApp = async app_id => {
    setIsLoading(true);
    try {
      const res = await enableAppIntegration(app_id);
      if (res && res.status === 200) {
        setIsLoading(false);

        Modal.success({
          title: `vPay setup successful!. You will logged out, Kindly log back in to complete vPay setup`,
          onOk: ()=>{
            signoutUser(client);
            setShowVpayMessage(false);
          }
        })
        
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const enableStripeLoyaltyApp = async app_id => {
    setIsLoading(true);
    try {
      const res = await enableAppIntegration(app_id);
      if (res && res.status === 200) {
        setIsLoading(false);

        Modal.success({
          title: `Stripe loyalty setup completed!. You will logged out, Kindly log back in to complete stripe setup`,
          onOk: ()=>{
            signoutUser(client);
            setShowStripDetails(false);
          }
        })
        
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      Modal.error({
        title: "Unable to complete strip integration, please try again!"
      })
    }
  };

  const handleSetNitroLocation = async () =>{

    if(nitroLocation === ""){
      base_url ? enableApp(id) : openInNewTab(website_link, true);
      setShowNitroMessage(false);
    }else{
      setIsNitroLoading(true)
      const data = {
        "remote_ip": nitroLocation.toString()
      }
      // const param = nitroLocation.toString();
      
      const res = await api.merchants.updateNitroLocation( data );

      if( res.status === 200){
        setIsNitroLoading(false);

        base_url ? await enableNitroApp(id) : openInNewTab(website_link, true);

      }else if (res.status === 204){
        setIsNitroLoading(false);

        base_url ? await enableNitroApp(id) : openInNewTab(website_link, true);

      }

    }
  }

  const handleCreateValentineCategory = async () => {

    const response = await api.category.getAllCategories(0)

    const categories = response.data;

    let categoryName = [];
    await categories.map((cat, index)=>{
      categoryName.push(cat.name);
    })
    const myValCategory = ["Valentine for Her", "Valentine for Him", "Valentine for Myself", "Valentine for Couples"];

    if(categoryName.some(arr => myValCategory.includes(arr))) {
      const res = await enableAppIntegration(id);
      // console.log("true");
      if(res && res.status === 200) return Modal.success({
        title: "Your Valentine Product Category have been set up succesfully, start adding your products to be featured on the marketplace and make sales this valentine.",
        onOk: async () => await fetchActiveApps()
      })

    }else{
      myValCategory.map(async (categ, index) => {
        await api.category.addNewCategory({name: categ});
  
        if (index === 3) {
          const res = await enableAppIntegration(id);
  
          if(res && res.status === 200) return Modal.success({
            title: "Your Valentine Product Category have been set up succesfully, start adding your products to be featured on the marketplace and make sales this valentine.",
            onOk: async () => {
              await fetchActiveApps();
            }
          })
        }
      });
    }
  }

  const enableNitroApp = async app_id => {
    setIsLoading(true);
    try {
      const res = await enableAppIntegration(app_id);
      if (res && res.status === 200) {
        setIsLoading(false);

        Modal.success({
          title: `Successfully added ${nitroLocation}. You will be logged out, Kindly log back in to complete Nitro setup`,
          onOk: ()=>{
            signoutUser(client);
            setShowNitroMessage(false);
          }
        })
        
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const enableApp = async app_id => {
    const isMultiLevel = app_name === "Multi-Level Loyalty";
    const isBookingAndAppointments =
      app_name === "Services, Bookings & Appointments";

    // if (isMultiLevel && user.subscription_plan === "Basic") {
    //   Modal.info({
    //     title: "Upgrade Plan",
    //     content: <span>{strings.pleaseUpgradeYourPlanToPro}.</span>,
    //   });

    //   return;
    // }

    // if (isBookingAndAppointments && user.subscription_plan === "Basic") {
    //   Modal.info({
    //     title: "Upgrade Plan",
    //     content: <span>{strings.pleaseUpgradeYourPlanToPro}.</span>,
    //   });

    //   return;
    // }

    setIsLoading(true);
    try {
      const res = await enableAppIntegration(app_id);
      if (res && res.status === 200) {
        setIsLoading(false);

        const isUPay = app_name === "Instant USSD Transfer";
        const isStripe = app_name === "Stripe";
        console.log("is stript", isStripe, app_name);
        

        const stripeMessage = "Please complete on-boarding";

        const { message } = res.data;

        const uPayMessage = msg => `${msg}. ${strings.connectYourBankAccount}`;

        
        if( isStripe ){
          const { data } = res.data;
          const { url } = data;

          // window.open(url, "_blank");
          window.location.href = url;
        }

        Modal.success({
          title: isUPay ? uPayMessage(message) : isStripe ? stripeMessage : message,
          onOk: async () => {
            // history.push("/d/apps/multi-level/programs");
            const res = await fetchActiveApps();

            const subscribedApps = res.data;

            const isSubscribedtoMultiLevel = subscribedApps.find(
              app => app.app.app_name === "Multi-Level Loyalty"
            )
              ? true
              : false;

            const isSubscribedToBookings = subscribedApps.find(
              app => app.app.app_name === "Services, Bookings & Appointments"
            )
              ? true
              : false;

            changeMultiLevelStatus(isSubscribedtoMultiLevel);
            changeBookingStatus(isSubscribedToBookings);

            isUPay &&
              history.push({
                pathname: "/d/account",
                state: {
                  isFromAppStore: true,
                },
              });
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const disableApp = async app_id => {
    setIsLoading(true);
    try {
      const res = await disableAppIntegration(app_id);
      if (res && res.status === 200) {
        setIsLoading(false);

        const isStripe = app_name === "Stripe";
        const isStripeLoyalty = app_name === "Stripe Loyalty";

        isStripe && activateStripForLoystar(false);
        isStripeLoyalty && activateStripeLoyalty(false);
    
        const { message } = res.data;

        Modal.success({
          title: message,
          onOk: async () => {
            await fetchActiveApps();
            toggleViewDetailsModal();
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const convertToDollar = (amount) => {
    if(user.currency === "NGN"){
      return Number(amount).toFixed(2);
    }else{
      return (Number(amount) / nairaDollarRate).toFixed(2);
    }
  }

  const handleActivateReferralProgram = () => {

    if(!orgPassword) return Modal.error({title: "Enter your password"})

    const payload = {
      ...user,
      organisation_phone_number: user.contact_number,
      password: orgPassword, 
      organisation_email: user.email,
      organisation_banner: user.image,
      organisation_logo: user.cover_image,
      organisation_address: `${user.addressline1 + "," || ""}${user.addressline1 || ""}`
    }

    setIsLoading(true);
    createOrganization(payload)
    .then(res => {
      const { organisation_id, token } = res.data;
      const merchant = JSON.parse(localStorage.getItem("userDetails"))
      const newMerchant = {
        ...merchant, 
        organization_id: organisation_id, 
        organization_token: token
      }
      localStorage.setItem("userDetails", JSON.stringify(newMerchant));
      localStorage.setItem("organisation_Details", JSON.stringify({
        token, 
        organization_id:organisation_id
      }));
      setShowOrgPassword(false);
      enableApp(id)
    })
    .catch(err => {
      if(err.response?.data?.message === "Organisation with this Phone Number exists. Phone Number must be unique!"){
        const payload = {
          password: orgPassword, 
          email: user.email
        }

        setIsLoading(true);
        loginOrganisation(payload)
        .then((res) => {
          const { organisation, token } = res;
          const merchant = JSON.parse(localStorage.getItem("userDetails"))
          const newMerchant = {
            ...merchant, 
            organization_id: organisation.organisation_id, 
            organization_token: token
          }
          localStorage.setItem("userDetails", JSON.stringify(newMerchant));
          localStorage.setItem("organisation_details", JSON.stringify({
            token, 
            organization_id: organisation.organisation_id
          }));
          setShowOrgPassword(false);
          enableApp(id);
        })
        .catch((err) => {
          message.error(`${err.response?.data?.message || "Failed to enable app"}, pls try again`);
          setIsLoading(false);
        })
      }else{
      message.error(`${err.response?.data?.message || "Failed to enable app"}, pls try again`);
      setIsLoading(false);
      }
    })
  }
  // console.log("s", !currencyForStripe.includes(user.currency));

  const isAppEnabled = subscribedApps.find(
    ({ app }) => app.app_name === app_name
  );

  return (
    <>
    <Modal
      title={app_name}
      open={visible}
      onCancel={toggleViewDetailsModal}
      footer={[
        <Button key="cancel" onClick={toggleViewDetailsModal}>
          {strings.cancel}
        </Button>,

        isAppEnabled ? (
          <Button
            onClick={() => disableApp(id)}
            type="primary"
            key="disable"
            loading={isLoading}
          >
            {strings.disable}
          </Button>
        ) : (
          <Button
            type="primary"
            key="enable"
            onClick={() => {
              // website_link ? openInNewTab(website_link, true) : enableApp(id);
              if(app_name.toLowerCase().includes("nitro") && user.subscription_plan !== "Nitro"){
                return Modal.success({
                  title: `You need to be on Nitro plan to enable this app`
                })
              }
              // if(app_name.toLowerCase().includes("appointment") && user.subscription_plan === cantSubcribeTorAppointment){
              //   return Modal.success({
              //     title: `You need to be on Pro or Pro Plus plan to enable this app`
              //   })
              // }

              if(app_name === "Stripe" && !currencyForStripe.includes(user.currency)) return Modal.success({
                  title: `This feature is only allowed for GBP, USD, EUR & CAD currencies`
                });
              
                if(app_name === "Airtime Rewards" && user.currency !== "NGN") return Modal.success({
                  title: `This feature is only allowed for NGN currency`
                });

              if(app_name === "WhatsApp Referral Program") {
                if(user.subscription_plan === "Free") return Modal.info({
                  title: "You need to be on a paid plan to enable this app"
                })
                const merchantDetails = JSON.parse(localStorage.getItem("userDetails"));
                if(merchantDetails.organization_id) return enableApp(id);
                return setShowOrgPassword(true);
              }

              if(app_name === "stripe Terminal" && !currencyForStripe.includes(user.currency)){
                return Modal.success({
                  title: `This feature is only allowed for GBP, USD, EUR & CAD currencies`
                })
              }
              // if(app_name.toLowerCase().includes("multi-level") && user.subscription_plan !== "Pro"){
              //   return Modal.success({
              //     title: `You need to be on Pro plan to enable this app`
              //   })
              // }
              // if(app_name.toLowerCase().includes("product bundles") && user.subscription_plan !== "Pro"){
              //   return Modal.success({
              //     title: `You need to be on Pro plan to enable this app`
              //   })
              // }
              if(app_name === "Loystar KDS - Kitchen Display System" && user.subscription_plan !== "Pro"){
                return Modal.info({
                  title: `You need to be on Pro plan to enable this app`
                })
              }
              if(app_name === "Stripe Loyalty" && !currencyForStripe.includes(user.currency)){
                return Modal.info({
                  title: `This feature is only allowed for GBP, USD, EUR & CAD currencies`
                })
              }
              if(app_name === "Myvalentineplug.com") return handleCreateValentineCategory()

              if(app_name === "Credpal Loystar Credit Service") return enableApp(id);
              
              if(app_name.toLowerCase().includes("nitro")){
                setShowNitroMessage(true);
              }else if(app_name.toLowerCase().includes("vpay")){
                setShowVpayMessage(true);
              }else if(app_name.toLowerCase().includes("paystack")){
                setShowPayStackMessage(true);
              }else if(app_name === "Stripe Loyalty"){
                setShowStripDetails(true);
              }else{
                base_url ? enableApp(id) : openInNewTab(website_link, true);
              }
            }}
            disabled={!website_link && !base_url}
            loading={isLoading}
          >
            {strings.enableApp}
          </Button>
        ),
      ]}
      // style={{width:620}}
      className="app_modal"
    >
      <div className="integration-details">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1.5rem",
          }}
        >
          <div className="img-wrapper" style={{ width: "100px" }}>
            <img src={icon} alt={app_name} />
          </div>

          <div className="name" style={{ marginLeft: "2rem" }}>
            <p style={{ marginBottom: 0, fontWeight: 700 }}>{app_name}</p>
            <p>{company_name}</p>
          </div>
        </div>

        <p>
          <strong>{strings.description}: </strong>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
        </p>

        <p>
          <strong>{strings.cost}:</strong> {price > 0 && user.currency}{price > 0 ? convertToDollar(price) : "Free"}
        </p>

        {website_link && (
          <Button
            type="link"
            href={website_link}
            target="_blank"
            rel="noreferrer noopener"
            style={{ paddingLeft: 0 }}
          >
            {strings.visitWebsite}
          </Button>
        )}
      </div>
    </Modal>
    
    {showNitroMessage 
      && 
        <Modal
          open={showNitroMessage}
          onCancel={()=>setShowNitroMessage(false)}
          onOk={handleSetNitroLocation}
          title="Set Nitro Location"
          confirmLoading={isNitroLoading}
        >
          <Input 
              value={nitroLocation}
              onChange={(e)=>setNitroLocation(e.target.value)}
              placeholder="http://1:1.1.1:7890"
            />
            <p>Please leave blank if this computer will serve as the nitro client. For questions and inquiry contact Loystar support - <a href="mailto:support@loystar.co">support@loystar.co</a></p>
        </Modal> 
    }
    {showPayStackMessage 
      && 
        <Modal
          open={showPayStackMessage}
          onCancel={()=>setShowPayStackMessage(false)}
          onOk={handlePayStackDetails}
          title="Set up Paystack credentials"
          confirmLoading={isPayStackLoading}
        >
          
          <Input 
            value={publicStackToken}
            onChange={(e)=>setPublicStackToken(e.target.value)}
            placeholder="Public key"
            type="password"
          />
          <div style={{height: "7px"}}></div>
          <Input 
            value={stackToken}
            onChange={(e)=>setStackToken(e.target.value)}
            placeholder="Secret Key"
            type="password"
          />
          <span>secret keys are encrypted and securely stored</span>
          <div style={{height: "7px"}}></div>
          {/* {setTerminal && <div>
            <Input 
              value={terminalId}
              onChange={(e)=>setTerminalId(e.target.value)}
              placeholder="Set terminal ID"
            />
            <div style={{height: "7px"}}></div>
            <Input 
              value={terminalLabel}
              onChange={(e)=>setTerminalLabel(e.target.value)}
              placeholder="set terminal label"
            />
            <div style={{height: "7px"}}></div>
            <Input 
              value={terminalProvider}
              onChange={(e)=>setTerminalProvider(e.target.value)}
              placeholder="set terminal provider"
            />
            <div style={{height: "7px"}}></div>
          </div>}
          {
            <Button
              type="primary"
              onClick={()=>setSetTerminal(!setTerminal)}
            >
              Assign terminal to cashier
            </Button>
          } */}
            {/* <p>Please leave blank if this computer will serve as the nitro client. For questions and inquiry contact Loystar support - <a href="mailto:support@loystar.co">support@loystar.co</a></p> */}
        </Modal> 
    }
    
    {showVpayMessage && <Modal
          open={showVpayMessage}
          onCancel={()=>setShowVpayMessage(false)}
          onOk={handleSaveVpaySettings}
          title="Set Vpay Settings"
          confirmLoading={isSaveVpayLoading}
        >
          <p>Please enter your Vpay settings. For questions and inquiry contact Loystar support - <a href="mailto:support@loystar.co">support@loystar.co</a></p>
          <Input 
            value={vPayEmail}
            onChange={(e)=>setVpayEmail(e.target.value)}
            placeholder="vpay_email"
            style={{marginBottom: "5px"}}
          />
          <Input 
            value={vPayPassword}
            onChange={(e)=>setVpayPassword(e.target.value)}
            placeholder="vpay_password"
            type="password"
            style={{marginBottom: "5px"}}
          />
          <Input 
            value={vPayPrivk}
            onChange={(e)=>setVpayPrivk(e.target.value)}
            placeholder="vpay_privk"
            type="password"
            style={{marginBottom: "5px"}}
          />
          <Input 
            value={vPayPubk}
            onChange={(e)=>setVpayPubk(e.target.value)}
            placeholder="vpay_pubk"
            type="password"
          />
        </Modal> 
      }

      {showStripDetails && 
        <Modal
          open={showStripDetails}
          onCancel={()=>setShowStripDetails()}
          title="Set Stripe Details"
          onOk={()=>handleSetStripeKey()}
          confirmLoading={isStripeLoading}
        >
          <p>Please enter your stripe payment settings. For questions and inquiry contact Loystar support - <a href="mailto:support@loystar.co">support@loystar.co</a></p>
          <Input 
            value={stripeSecretKey}
            onChange={(e)=>setStripeSecretKey(e.target.value)}
            placeholder="stripe secret key"
            type="password"
            style={{marginBottom: "10px"}}
          />
          <Input 
            value={stripeAccountWebhookToken}
            onChange={(e)=>setStripeAccountWebhookToken(e.target.value)}
            placeholder="stripe account webhook token"
            type="password"
            style={{marginBottom: "10px"}}
          />
          <Input 
            value={stripePublishableKey}
            onChange={(e)=>setStripePublishableKey(e.target.value)}
            placeholder="stripe publishable key"
            type="password"
            style={{marginBottom: "10px"}}
          />
        </Modal>
      }
      {showOrgPassword && (
        <Modal
          title="Enter password to continue"
          onOk={handleActivateReferralProgram}
          open={showOrgPassword}
          onCancel={() => setShowOrgPassword(false)}
          confirmLoading={isLoading}
        >
          <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <Input 
              placeholder="Email"
              value={user.email}
              disabled
            />

            <Input.Password 
              placeholder="password"
              onChange={(e) => setOrgPassword(e.target.value)}
            />
          </div>
        </Modal>
      )}
    </>

  )
}

const mapStateToProps = state => ({
  subscribedApps: state.appStore.subscribedApps,
  user: state.auth.user,
  subscriptionStatus: state.renewal.subscriptionStatus,
});

export default connect(mapStateToProps, {
  enableAppIntegration,
  disableAppIntegration,
  changeMultiLevelStatus,
  fetchActiveApps,
  signoutUser,
  getPricingPlanData,
  activateStripForLoystar,
  activateStripeLoyalty
})(ViewDetailsModal);
